<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoAcaoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>

      <v-radio-group class="PlanejamentoAcoesFiltros__Opcoes"
        v-model="tipoFiltro"
        v-show="filtrosAbertos"
        dense row>
        <v-radio :label="$tc('label.em_comum', 1)" value="comum"></v-radio>
        <v-radio :label="$t('label.todos')" value="todos"></v-radio>
      </v-radio-group>

      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass">
        fa-thumbtack
      </v-icon>
      <v-badge
        bordered
        color="orange"
        icon="mdi-lock"
        overlap
        v-if="habilitaIconBadge">
        <v-icon @click="toggleFiltros">filter_list</v-icon>
      </v-badge>
      <v-icon v-else @click="toggleFiltros">filter_list</v-icon>
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        v-if="metadadosPlanejamentoAcao != null && carregouCampos"
        :metadados-entidade="metadadosPlanejamentoAcao"
        :ordenacao-campos="ordenacaoSelecionada"
        :campos-formulario="camposFormulario"
        :explode-hierarquia="true"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :ignora-desabilitados="true"
        :ignora-inativos="false"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoAcao"
        :mensagem-tooltip="mensagemTooltip"
        ref="container"
        >
        <v-col cols="12" sm="6" md="3" slot="depois">
          <v-autocomplete
            id="filtro_modalidade"
            class="custom-autocomplete"
            v-model="filtrosPlanejamentoAcao.modalidade"
            :label="`${$tc('label.modalidade', 1)}`"
            :items="listaModalidadePorAmbiente"
            clearable
            chips
            multiple
            deletable-chips
            item-text="texto"
            item-value="valor"
            :no-data-text="$tc('message.nenhum_registro', 1)"
          />
        </v-col>
      </metadados-container-layout>
      <metadados-container-layout
        v-if="metadadosPlanejamentoAcao != null && carregouCampos && ordenacaoSelecionadaFoco.length"
        :metadados-entidade="metadadosPlanejamentoAcao"
        :ordenacao-campos="ordenacaoSelecionadaFoco"
        :campos-formulario="camposFoco"
        :explode-hierarquia="true"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :ignora-desabilitados="true"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoAcaoFoco"
        ref="containerFoco"
        style="border: thin solid rgba(0,0,0,.12); border-radius: 4px;"
        >
        <template v-slot:antes>
          <v-col cols="12" class="py-0" v-if="ordenadosFoco.length">
            <v-subheader class="pl-0">{{ indModoVarejo ? $tc('label.origem_acao') : $tc('label.foco_acao') }}</v-subheader>
          </v-col>
        </template>
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { skipLoading } from '../../../common/functions/loading';
import { copyObject } from '../../../common/functions/helpers';

import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoFiltrosCampos from './PlanejamentoAcaoFiltrosCampos';

export default {
  name: 'PlanejamentoAcaoFiltros',
  mixins: [
    PlanejamentoAcaoFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    ordenacaoSelecionada() {
      return this.ordenacaoCampos[this.tipoFiltro] || [];
    },
    ordenacaoSelecionadaFoco() {
      const todosCampos = [
        ...this.camposFoco.padrao || [],
      ];
      this.ordenadosFoco = [];
      this.ordenacaoCampos[this.tipoFiltro].forEach((label) => {
        for (let i = 0; i < todosCampos.length; i += 1) {
          const campo = todosCampos[i];
          const labelCampo = campo.labelCampo || campo.label;
          if (labelCampo === label) {
            this.ordenadosFoco.push(campo.labelCampo);
            break;
          }
        }
      });
      return this.ordenadosFoco;
    },
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,
      carregouCampos: false,
      habilitaIconBadge: false,
      countFiltro: 0,
      mensagemTooltip: this.$t('label.codigo_enviado_fornecedor_campanha'),

      metadadosPlanejamentoAcao: null,

      ordenacaoCampos: {},
      ordenadosFoco: [],
      tipoFiltro: 'todos',

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosPlanejamentoAcao: {},
      filtrosPlanejamentoAcaoFoco: {},
      workspace: {},
      tiposDeInvestimento: null,

      entidadeWorkspace: 'planejamento_acao',
    };
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosPlanejamentoAcao = this.workspace.filtros || {};
          if (Object.values(this.filtrosPlanejamentoAcao).length > 1) {
            this.habilitaFlagBadge(true);
          }
          this.unidadesNegocioDivisao = [this.filtrosPlanejamentoAcao.unidadeNegocio];

          this.tipoFiltro = this.filtrosPlanejamentoAcao.tipoFiltro || 'todos';

          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosPlanejamentoAcao = {};
      this.filtrosPlanejamentoAcaoFoco = {};
      this.habilitaIconBadge = false;
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      let parametros = {};
      if (this.$refs.container) {
        parametros = {
          ...this.$refs.container.getValoresDependencias(),
          ...this.$refs.container.getValoresCamposPadrao(),
          ...this.$refs.container.getValoresCamposDinamicos(),
        };
      }
      if (this.$refs.containerFoco) {
        parametros = {
          ...parametros,
          ...this.$refs.containerFoco.getValoresDependencias(),
          ...this.$refs.containerFoco.getValoresCamposPadrao(),
          ...this.$refs.containerFoco.getValoresCamposDinamicos(),
        };
      }

      parametros.modalidade = this.filtrosPlanejamentoAcao.modalidade;
      this.$emit('PlanejamentoAcaoFiltros__AplicaFiltros', parametros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
      this.toggleFiltros();
    },
    salvarFiltrosWorkspace() {
      this.verificaCamposPreenchidosBadge();
      this.workspace.filtros = this.filtrosPlanejamentoAcao;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    verificaCamposPreenchidosBadge() {
      const filtroAcao = this.filtrosPlanejamentoAcao;
      if (filtroAcao.tipoFiltro) {
        delete filtroAcao.tipoFiltro;
      }
      const filtroAcoes = Object.values(filtroAcao);
      const filtroAcaoFoco = Object.values(this.filtrosPlanejamentoAcaoFoco);
      if (filtroAcaoFoco.length) {
        filtroAcaoFoco.forEach((acao) => {
          filtroAcoes.push(acao);
        });
      }
      this.habilitaBadge(filtroAcoes);
    },
    habilitaBadge(filtroAcoes) {
      if (filtroAcoes.length !== 0) {
        filtroAcoes.forEach((item) => {
          if (item === null || item.length === 0 || !item) {
            this.countFiltro += 1;
          }
        });
        if (filtroAcoes.length === this.countFiltro) {
          this.filtrosPlanejamentoAcao = {};
          this.habilitaFlagBadge(false);
        } else {
          this.habilitaFlagBadge(true);
        }
        this.countFiltro = 0;
      }
    },
    habilitaFlagBadge(valor) {
      this.habilitaIconBadge = valor;
    },
    habilitaCamposExtra() {
      const chaves = Object.keys(this.ordenacaoCampos);
      chaves.forEach((chave) => {
        const campos = this.ordenacaoCampos[chave];

        const indicePeriodo = campos.indexOf('periodo');
        const indiceReferencial = indicePeriodo + 1;
        if (indicePeriodo >= 0) {
          campos.splice(indiceReferencial, 0, 'data_inicio');
          campos.splice(indiceReferencial + 1, 0, 'data_fim');
        }
        campos.unshift('cod_fornecedor_acao');
        campos.unshift('id_acao_campanha');
        campos.unshift('tipo_acao');
        campos.push('minhas_aprovacoes_pendentes');
        campos.push('descricao_passo');
      });
    },
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
          if (this.usuarioFornecedor) {
            this.removeFiltrosParaAcessoFornecedor();
          }

          this.habilitaCamposExtra();
          this.carregarWorkspaceFiltros();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    removeFiltrosParaAcessoFornecedor() {
      const tiposFiltro = Object.keys(this.ordenacaoCampos);
      tiposFiltro.forEach((tipo) => {
        this.ordenacaoCampos[tipo] = this.ordenacaoCampos[tipo]
          .filter((f) => [
            'divisao',
            'grupo_fornecedor',
            'centro_custo',
          ].indexOf(f) < 0);
      });
    },
    preencheHoldings() {
      this.configuracaoResource
        .buscarHoldingsCliente()
        .then((res) => {
          if (this.filtrosPlanejamentoAcao.holding === null
                || this.filtrosPlanejamentoAcao.holding === undefined) {
            this.filtrosPlanejamentoAcao.holding = res.body;
            this.$refs.container.refresh();
          }
          if (this.filtrosPlanejamentoAcaoFoco.holding === null
                || this.filtrosPlanejamentoAcaoFoco.holding === undefined) {
            this.filtrosPlanejamentoAcaoFoco.holding = res.body;
            this.$refs.containerFoco.refresh();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getAcaoMetadado;
    this.metadadosPlanejamentoAcao = copyObject(metadados);
    this.carregaCamposFiltros();
    if (this.isTenantEpoca) {
      this.listaModalidadePorAmbiente = this.listaModalidadeEpoca;
    } else {
      this.listaModalidadePorAmbiente = this.listaModalidade;
    }
  },
};
</script>
<style>
  .PlanejamentoAcoesFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-input__slot,
  .PlanejamentoAcoesFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoAcoesFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
