<style>
  .PlanejamentoAcaoList__Modal {
    text-align: left;
    display: inline-block;
  }
  .PlanejamentoAcaoList__Modal .headline {
    width: 100%;
    text-align: center;
  }
  .PlanejamentoAcaoList__Modal_Div {
    text-align: center;
  }
</style>
 <template>
  <div class="PlanejamentoAcaoList__Modal_Div">
    <v-card class="PlanejamentoAcaoList__Modal">
      <v-card-title>
        <span class="headline">
          {{ $t('title.selecione_tipo_acao') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="filtroTipo"
          @input="page = 1; buscarTiposAcao(filtroTipo)"
          :label="$t('label.filtrar')"
          clearable
          append-icon="search">
        </v-text-field>
        <v-list class="PlanejamentoAcaoList__Lista">
          <v-list-item
            v-for="(item, index) in categorias"
            :key="index"
            class="PlanejamentoAcaoList__TipoAcao"
            ripple>
            <v-list-item-content>
              <v-list-group
                :value="false"
                v-if="item.categoria"
                no-action>
                <template slot="activator">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ item.categoria }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  @click="selecionarAcao(tipo)"
                  v-for="(tipo, i) in item.tipos"
                  :key="i"
                >
                <v-list-item-title class="primary--text" v-text="tipo.nome"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon color="primary">arrow_forward</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
              <v-list-item @click="selecionarAcao(item)" v-else>
              <v-list-item-title class="primary--text" v-text="item.nome"></v-list-item-title>
                <v-list-item-icon v-if="!item.categoria">
                  <v-icon color="primary">arrow_forward</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click.native="cancelarCriacaoAcao">{{ $t('label.cancelar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      filtroTipo: null,
      page: 1,
      quantidadePagina: 1,
      tiposAcao: [],
      categorias: [],
    };
  },
  watch: {
    page() {
      this.buscarTiposAcao(this.filtroTipo);
    },
  },
  methods: {
    buscarTiposAcao(nome = null) {
      this.planejamentoAcaoResource.buscarTiposAcao({ nome, size: 1000, page: this.page })
        .then((res) => {
          this.tiposAcao = res.data.resposta;
          this.categorias = this.agruparPorCategoria(res.data.resposta);
          this.exibeModalNovaAcao = true;
          this.quantidadePagina = Math.ceil(res.data.quantidadeRegistrosPagina / 10);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    agruparPorCategoria(lista) {
      const listaFiltrada = lista.map((item) => item.categoria_acao);
      const categorias = new Set(listaFiltrada);
      const categoriasFiltradas = [...categorias].filter((cat) => cat && cat.length);

      const resultado = [];
      categoriasFiltradas.forEach((cat) => {
        resultado.push({
          categoria: cat,
          tipos: lista.filter((tipo) => tipo.categoria_acao === cat),
        });
      });

      lista.filter((tipo) => !tipo.categoria_acao || !tipo.categoria_acao.length)
        .forEach((tipo) => resultado.push(tipo));

      return resultado;
    },
    cancelarCriacaoAcao() {
      this.$emit('PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao', 1);
    },
    selecionarAcao(tipoAcao) {
      const params = {
        idTipoAcao: tipoAcao.id,
        numPassoAtual: 1,
      };
      if (tipoAcao.template === 'VERBA_VARIAVEL') {
        this.$emit('PlanejamentoAcaoModalNovaAcao__AvancarPassoModal', params);
      } else {
        this.$emit('PlanejamentoAcaoModalNovaAcao__CriarNovaAcao', params);
      }
    },
  },
  mounted() {
    this.buscarTiposAcao();
  },
};
</script>
