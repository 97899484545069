<style>
  .PlanejamentoAcaoList__Modal {
    text-align: left;
    display: inline-block;
  }
  .PlanejamentoAcaoList__Modal .headline {
    width: 100%;
    text-align: center;
  }
  .PlanejamentoAcaoList__Modal_Div {
    text-align: center;
  }
</style>
 <template>
  <div class="PlanejamentoAcaoList__Modal_Div">
    <v-card class="PlanejamentoAcaoList__Modal">
      <v-card-title>
        <span class="headline">
          {{ $t('title.defina_gatilho_acao') }}
        </span>
      </v-card-title>
      <v-card-text class="my-0 py-0" style="min-height: 20em">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-radio-group
                  id="modal_nova_calculo_apuracao"
                  v-model="configuracaoAcaoVerbaVariavelPasso3.calculoApuracao"
                  :label="`${$tc('label.tipo_gatilho', 1)} *`"
                  class="mt-0"
                  dense
                  hide-details
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(ca, index) in listaCalculosApuracaoFiltrada"
                    :key="index"
                    class="my-0"
                    :label="`${$t('label.' + ca.toLowerCase())}`" :value="ca"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" dark @click.native="voltarPassoModal">{{ $t('label.anterior') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click.native="cancelarCriacaoAcao">{{ $t('label.cancelar') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click.native="abrirModalConfirmacao">{{ $t('label.criar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.criar_acao')"
      :persistent="true"
      @agree="criarNovaAcao">
    </confirm>
  </div>
</template>
<script>
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    tipoAcaoSelecionada: {
      type: Object,
      default: {},
      required: true,
    },
  },
  components: {
    Confirm,
  },
  watch: {
    tipoAcaoSelecionada() {
      this.listaCalculosApuracao = [...this.tipoAcaoSelecionada.configuracao.calculosApuracao.sort()];
      if (this.listaCalculosApuracao.length === 1) {
        [this.configuracaoAcaoVerbaVariavelPasso3.calculoApuracao] = this.listaCalculosApuracao;
      }
    },
  },
  computed: {
    isCalculoBonificacaoSellin() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao === 'SELLIN';
    },
    isCalculoBonificacaoSellout() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao === 'SELLOUT';
    },
    isCalculoBonificacaoFaturamentoFornecedor() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao === 'FATURAMENTO_FORNECEDOR';
    },
    verbaFixo() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao === 'FIXO';
    },
    verbaPercentual() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao === 'PERCENTUAL';
    },
    verbaVariavel() {
      return this.configuracaoAcaoVerbaVariavelPasso2
        && this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao === 'VALOR_POR_PECA';
    },
    listaCalculosApuracaoFiltrada() {
      if (!this.configuracaoAcaoVerbaVariavelPasso2) {
        return this.listaCalculosApuracao;
      }
      if (this.verbaVariavel) {
        return this.listaCalculosApuracao.filter((b) => b === 'QUANTIDADE');
      }
      if ((this.isCalculoBonificacaoSellin || this.isCalculoBonificacaoFaturamentoFornecedor)
        && (this.verbaFixo || this.verbaPercentual)) {
        return this.listaCalculosApuracao.filter((b) => b === 'QUANTIDADE' || b === 'SELLIN_VALOR');
      }
      if (this.isCalculoBonificacaoSellout) {
        if (this.verbaFixo || this.verbaPercentual) {
          return this.listaCalculosApuracao.filter((b) => b !== 'SELLIN_VALOR' && b !== 'SELLIN_PERCENTUAL' && b !== 'SELLOUT_PERCENTUAL');
        }
      }
      return this.listaCalculosApuracao;
    },
  },
  data() {
    return {
      listaCalculosApuracao: [],
      configuracaoAcaoVerbaVariavelPasso2: {},
      configuracaoAcaoVerbaVariavelPasso3: {
        calculoApuracao: null,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    resetConfiguracao() {
      this.configuracaoAcaoVerbaVariavelPasso3 = {
        calculoApuracao: null,
      };
      this.$refs.form.resetValidation();
    },
    cancelarCriacaoAcao() {
      this.$emit('PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao', 3);
    },
    voltarPassoModal() {
      this.$emit('PlanejamentoAcaoModalNovaAcao__VoltarPassoModal', 3);
    },
    setaConfiguracaoPasso2(configuracaoPasso2) {
      this.configuracaoAcaoVerbaVariavelPasso2 = configuracaoPasso2;
    },
    abrirModalConfirmacao() {
      this.$refs.confirmDialog.open();
    },
    criarNovaAcao() {
      if (!this.$refs.form.validate()) return;

      const params = {
        ...this.configuracaoAcaoVerbaVariavelPasso3,
        numPassoAtual: 3,
      };
      this.$emit('PlanejamentoAcaoModalNovaAcao__CriarNovaAcao', params);
    },
  },
  mounted() {
  },
};
</script>
