<style>
.PlanejamentoAcaoList {
  padding-bottom: 60px;
}
.PlanejamentoAcaoList__Lista {
  margin-top: 20px;
}
.PlanejamentoAcaoList_dialog {
  width: auto;
}
</style>
<template>
  <div class="PlanejamentoAcaoList">
    <planejamento-acao-filtros
      ref="filtros"
      @PlanejamentoAcaoFiltros__AplicaFiltros="aplicaFiltros">
    </planejamento-acao-filtros>
    <planejamento-acao-dados
      ref="dados"
      @PlanejamentoAcaoDados__AplicaFiltros="alterarAgrupamento">
    </planejamento-acao-dados>

    <v-btn fab
      bottom right fixed
      class="v-btn__fab"
      color="primary"
      @click.native="abrirModalNovaAcao"
      v-show="canEdit">
      <v-icon>add</v-icon>
    </v-btn>

    <v-dialog content-class="PlanejamentoAcaoList_dialog" v-model="exibeModalNovaAcaoPasso1">
      <planejamento-acao-modal-nova-acao-passo-1
        @PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao="fecharModalNovaAcao"
        @PlanejamentoAcaoModalNovaAcao__AvancarPassoModal="avancarPassoModal"
        @PlanejamentoAcaoModalNovaAcao__CriarNovaAcao="criarNovaAcao"
        ref="modalNovaAcaoPasso1">
      </planejamento-acao-modal-nova-acao-passo-1>
    </v-dialog>
    <v-dialog content-class="PlanejamentoAcaoList_dialog" v-model="exibeModalNovaAcaoPasso2" eager>
      <planejamento-acao-modal-nova-acao-passo-2
        :tipo-acao-selecionada="tipoAcaoSelecionada"
        @PlanejamentoAcaoModalNovaAcao__VoltarPassoModal="voltarPassoModal"
        @PlanejamentoAcaoModalNovaAcao__AvancarPassoModal="avancarPassoModal"
        @PlanejamentoAcaoModalNovaAcao__CriarNovaAcao="criarNovaAcaoVerbaVariavel"
        ref="modalNovaAcaoPasso2">
      </planejamento-acao-modal-nova-acao-passo-2>
    </v-dialog>
    <v-dialog content-class="PlanejamentoAcaoList_dialog" v-model="exibeModalNovaAcaoPasso3" eager>
      <planejamento-acao-modal-nova-acao-passo-3
        :tipo-acao-selecionada="tipoAcaoSelecionada"
        @PlanejamentoAcaoModalNovaAcao__FecharModalNovaAcao="fecharModalNovaAcao"
        @PlanejamentoAcaoModalNovaAcao__VoltarPassoModal="voltarPassoModal"
        @PlanejamentoAcaoModalNovaAcao__CriarNovaAcao="criarNovaAcaoVerbaVariavel"
        ref="modalNovaAcaoPasso3">
      </planejamento-acao-modal-nova-acao-passo-3>
    </v-dialog>
  </div>
</template>
<script>

import PlanejamentoAcaoFiltros from '@/spa/planejamento-acao/list/PlanejamentoAcaoFiltros';
import PlanejamentoAcaoDados from '@/spa/planejamento-acao/list/PlanejamentoAcaoDados';
import PlanejamentoAcaoModalNovaAcaoPasso1 from './PlanejamentoAcaoModalNovaAcaoPasso1';
import PlanejamentoAcaoModalNovaAcaoPasso2 from './PlanejamentoAcaoModalNovaAcaoPasso2';
import PlanejamentoAcaoModalNovaAcaoPasso3 from './PlanejamentoAcaoModalNovaAcaoPasso3';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import AcaoConfiguracao from '../AcaoConfiguracao';

export default {
  components: {
    PlanejamentoAcaoFiltros,
    PlanejamentoAcaoDados,
    PlanejamentoAcaoModalNovaAcaoPasso1,
    PlanejamentoAcaoModalNovaAcaoPasso2,
    PlanejamentoAcaoModalNovaAcaoPasso3,
  },
  computed: {
    ...generateComputed('ACAO', [
      'canAccessPage',
      'canEdit',
    ]),
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      exibeModalNovaAcaoPasso1: false,
      exibeModalNovaAcaoPasso2: false,
      exibeModalNovaAcaoPasso3: false,
      filtroTipo: null,
      filtros: {},
      quantidadePagina: 1,
      tipoAcaoSelecionada: {},
      configuracoesPasso2: {},
      configuracoesPasso3: {},
    };
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    abrirModalNovaAcao() {
      this.exibeModalNovaAcaoPasso1 = true;
    },
    fecharModalNovaAcao(passoAtual) {
      if (this.$refs.modalNovaAcaoPasso2) {
        this.$refs.modalNovaAcaoPasso2.resetConfiguracao();
      }
      if (this.$refs.modalNovaAcaoPasso3) {
        this.$refs.modalNovaAcaoPasso3.resetConfiguracao();
      }
      switch (passoAtual) {
        case 1:
          this.exibeModalNovaAcaoPasso1 = false;
          break;
        case 3:
          this.exibeModalNovaAcaoPasso3 = false;
          break;
        default:
      }
    },
    voltarPassoModal(numPassoAtual) {
      const numeroPassoAnterior = numPassoAtual - 1;
      switch (numeroPassoAnterior) {
        case 1:
          this.exibeModalNovaAcaoPasso2 = false;
          this.$refs.modalNovaAcaoPasso2.resetConfiguracao();
          this.exibeModalNovaAcaoPasso1 = true;
          break;
        case 2:
          this.exibeModalNovaAcaoPasso3 = false;
          this.$refs.modalNovaAcaoPasso3.resetConfiguracao();
          this.exibeModalNovaAcaoPasso2 = true;
          break;
        default:
      }
    },
    avancarPassoModal(dados) {
      const numeroProximoPasso = dados.numPassoAtual + 1;
      switch (numeroProximoPasso) {
        case 2:
          this.configuracaoResource.buscarConfigVigente({ idTipoAcao: dados.idTipoAcao })
            .then((res) => {
              this.tipoAcaoSelecionada = {
                idTipoAcao: dados.idTipoAcao,
                configuracao: new AcaoConfiguracao(res.data),
              };
              this.exibeModalNovaAcaoPasso1 = false;
              this.exibeModalNovaAcaoPasso2 = true;
            });
          break;
        case 3:
          this.configuracoesPasso2 = { ...dados };
          this.$refs.modalNovaAcaoPasso3.setaConfiguracaoPasso2(this.configuracoesPasso2);
          this.exibeModalNovaAcaoPasso2 = false;
          this.exibeModalNovaAcaoPasso3 = true;
          break;
        default:
      }
    },
    criarNovaAcao(dados) {
      const { idTipoAcao } = dados;
      this.$router.push({ name: 'novaAcao', params: { idTipoAcao } });
    },
    criarNovaAcaoVerbaVariavel(dados) {
      const numeroPassoAtual = dados.numPassoAtual;
      if (numeroPassoAtual === 2) {
        this.configuracoesPasso2 = { ...dados };
      } else if (numeroPassoAtual === 3) {
        this.configuracoesPasso3 = { ...dados };
      }
      const params = {
        ...this.tipoAcaoSelecionada,
        ...this.configuracoesPasso2,
        ...this.configuracoesPasso3,
      };
      this.$router.push({ name: 'novaAcaoVerbaVariavel', params });
    },
    alterarAgrupamento() {
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
    aplicaFiltros(filtros = {}) {
      this.filtros = filtros;
      setTimeout(() => this.$refs.dados.aplicaFiltros(filtros), 500);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
