<template>
  <div>
    <v-card class="PlanejamentoAcaoDados">

      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.acao', 2) }}</div>
        <planejamento-acao-dados-agrupamento
          ref="agrupamento"
          @PlanejamentoAcaoDadosAgrupamento_Alterado="alterarAgrupamento"
        />
      </v-card-title>
      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel">
        <v-expansion-panel
          v-for="(tipoAcao, index) in tiposAcao"
          :key="index">
          <v-expansion-panel-header>
            {{ tituloDoPainel(tipoAcao) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <planejamento-acao-tabela
              :tipo-acao="tipoAcao"
              :ordenacao="parametrizacaoAcao[tipoAcao.id]"
              :label-campo-cliente="labelsCampoCliente[tipoAcao.id]"
              :filtros="filtrosAplicados"
              :agrupamento="filtrosAplicados.agrupamento"
              :ref="`tabela-${tipoAcao.id}`"
              @refazer_busca="aplicaFiltros(null)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="5"
        @input="aplicaFiltros(null)"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import PlanejamentoAcaoTabela from './PlanejamentoAcaoTabela';
import PlanejamentoAcaoDadosAgrupamento from './PlanejamentoAcaoDadosAgrupamento';
import { buscarTiposAcao } from '../../../common/resources/planejamento/planejamento-acao-listagem';

export default {
  name: 'PlanejamentoAcaoDados',
  components: {
    PlanejamentoAcaoTabela,
    PlanejamentoAcaoDadosAgrupamento,
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      tiposAcao: [],
      painel: null,
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,

      filtrosAplicados: {},
      parametrizacaoAcao: {},
      labelsCampoCliente: {},
    };
  },
  methods: {
    async carregaParametrosTiposAcao(tiposAcao) {
      const listaIdsTipoAcao = [...new Set(tiposAcao.map((tipo) => tipo.id))];
      await Promise.all(listaIdsTipoAcao
        .map((id) => this.configuracaoResource
          .buscarCamposGrid({ idTipoAcao: id })
          .then((res) => {
            this.parametrizacaoAcao[id] = res.data;
          })));
    },
    async carregaLabelCampoCliente(tiposAcao) {
      const listaIdsTipoAcao = [...new Set(tiposAcao.map((tipo) => tipo.id))];
      await Promise.all(listaIdsTipoAcao
        .map((id) => this.configuracaoResource
          .buscarLabelCampoCliente({ idTipoAcao: id })
          .then((res) => {
            this.labelsCampoCliente[id] = res.bodyText;
          })));
    },
    tituloDoPainel(tipoAcao) {
      let titulo = `${this.$t('label.tipo_acao')} :: ${tipoAcao.nome}`;

      if (tipoAcao.agrupamento) {
        const { tipoAgrupamento, agrupamento } = tipoAcao;
        let labelTipo;

        if (['cliente', 'fornecedor'].indexOf(tipoAgrupamento) >= 0) {
          labelTipo = this.$t(`label.${tipoAgrupamento}`);
        } else {
          labelTipo = tipoAgrupamento;
        }

        titulo += ` :: ${labelTipo} :: ${agrupamento}`;
      }
      return titulo;
    },
    alterarAgrupamento() {
      this.pagina = 1;
      this.$emit('PlanejamentoAcaoDados__AplicaFiltros');
    },
    aplicaFiltros(filtros = null) {
      this.tiposAcao = null;
      this.painel = null;
      const agrupamento = this.$refs.agrupamento.agrupamentoHabilitado();
      let filtrosAnteriores = {};

      if (filtros) {
        if (filtros.id_acao_campanha) {
          filtros.id_acao = filtros.id_acao_campanha;
        }
      } else {
        filtrosAnteriores = this.filtrosAplicados;
      }

      this.filtrosAplicados = {
        ...filtros,
        ...filtrosAnteriores,
        numeroPagina: filtros ? 1 : this.pagina,
        tamanhoPagina: this.quantidadePagina,
        agrupamento,
      };

      buscarTiposAcao(this.filtrosAplicados, this.$resource)
        .then((res) => {
          const tiposAcao = res.data.resposta;
          this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
          this.carregaParametrosTiposAcao(tiposAcao)
            .then(() => this.carregaLabelCampoCliente(tiposAcao))
            .then(() => {
              this.tiposAcao = tiposAcao;
              this.iniciandoPainel = false;
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    setTimeout(() => this.$refs.agrupamento.registraBotoesAgrupamento());
  },
};
</script>
